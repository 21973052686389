














































































































































































































































.questionbankExcelBox {
  display: flex;
  width: 100%;
  height: 10rem;
  .questionbank_divBox {
    width: 50%;
    padding: 0.5rem;
    box-sizing: border-box;
    .questionbank_div {
      padding: 0.5rem;
      border: 1px solid #f9f9f9;
      height: 100%;
      background: #fafafa;
      display: flex;
      .questionbank_template {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex: 1;
        .upload-btn {
          height: 32px;
          .el-upload {
            height: 32px !important;
            border: none !important;
          }
        }
        .questionbank_button {
          margin-top: 1rem;
        }
      }
    }
  }
}
.FormatBox {
  padding: 1.75rem 1rem;
  .p_red {
    color: red;
  }
  .FormatDescriptionBox {
    padding-top: 0.75rem;
    line-height: 1rem;
  }
}
